@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "font-settings.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  a:not([class]) {
    @apply underline decoration-from-font;
  }

  a:not([class]):hover {
    @apply no-underline;
  }

  /* Fix Safari issue related to <summary> / <details> arrow */
  details > summary.list-none::-webkit-details-marker,
  details > summary.list-none::marker {
    display: none;
  }
}

@layer components {
  .noise {
    background-image: url("/assets/svg/noise.svg");
  }

  .inline-stroke {
    -webkit-text-stroke: .4px #ffffff80;
    -webkit-text-fill-color: transparent;
    text-shadow: -1px -1px 0 hsla(0, 0%, 100%, .1), 1px 1px 0 rgba(0, 0, 0, .05);
  }

  .input {
    @apply w-full px-3 py-2;
    @apply text-gray-800;
    @apply bg-gray-200;
    @apply ring-1 ring-offset-0 ring-gray-200;
    @apply rounded-md;

    &:not([readonly]):hover {
      @apply bg-white;
    }

    &:focus {
      @apply bg-white;
      @apply ring-gray-300;
      @apply placeholder-gray-500;
      @apply outline-none;
    }
  }

  .label {
    @apply text-sm font-medium text-gray-700;
  }
}
